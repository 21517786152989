<template>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="积分调整" name="first">
            <memberScoreSet></memberScoreSet>
        </el-tab-pane>
        <el-tab-pane label="积分流水" name="second">
            <memberScoreWater></memberScoreWater>
        </el-tab-pane>
    </el-tabs>
</template>
<script>
    import memberScoreSet from '@/components/memberScoreSet.vue'
    import memberScoreWater from '@/components/memberScoreWater.vue'
    export default {
        components:{
            memberScoreSet,
            memberScoreWater
        },
        data() {
            return {
                activeName: 'first'
            };
        },
        mounted(){
            document.title = '会员积分'
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            }
        }
    };
</script>
<style>
    
</style>
