<template>
    <div id="memberScoreSet">
      <hr> <br>
      <div>
              <el-form  :model="member_score_set" label-width="120px">
                  <el-form-item label="选择会员">
                      <el-input  size="small" placeholder="输入会员手机号" v-model="member_score_set.phone" style="width: 150px">

                      </el-input>
                      <el-button @click="getMemberInfo" style="position: relative;right: 5px" size="small" type="primary">检索</el-button>

                  </el-form-item>
                  <el-form-item label="" v-if="show_member_info">
                      <div style="border:1px solid rgb(64,158,255);width:250px;color:rgb(64,158,255)">
                            <ul style="padding: 10px">
                                <li>会员姓名：{{member_info.uname}}</li>
                                <li>联系电话：{{member_info.phone}}</li>
                                <li>会员地址：{{member_info.member_address}}</li>
                                <li>当前积分：{{member_info.score}}</li>
                            </ul>
                      </div>
                  </el-form-item>
                  <el-form-item label="积分调整">
                      <el-input-number size="small" v-model="member_score_set.num"></el-input-number>
                      <span style="color: red">*正数为增加多少积分负数为减少多少积分</span>
                  </el-form-item>
                  <el-form-item label="备注">
                      <el-input type="textarea" size="small" v-model="member_score_set.remark" style="width: 250px"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                      <el-button size="small" type="primary" @click="saveScore">确定</el-button>
                  </el-form-item>
              </el-form>
      </div>
    </div>
</template>
<script>
  export default {
    data () {
      return {
          show_member_info:false,
          member_score_set:{
                phone:'',
                num:0,
                remark:''
          },
          member_info:{
              mid:null,
              uname:'',
              phone:'',
              score:0,
              member_address:''
          }
      }
    },
    mounted(){

    },
    methods:{
        getMemberInfo(){
            let _this=this
            if(this.member_score_set.phone=='') return this.$message.error('请输入会员手机号')
            this.$post('/pc/member/getMemberScore',_this.member_score_set,function (res) {
                    let result = res.data
                    if(result.code==200){
                        _this.member_info=result.data
                        _this.show_member_info=true
                        return _this.$message.success(result.msg)
                    }
                        _this.$message.error(result.msg)

            })

        },
        saveScore(){
            let _this=this
            if(!this.show_member_info)  return this.$message.error('请先检索会员')
              if(this.member_score_set.num=='')  return this.$message.error('请输入调整的积分')

            let params = {
                mid:this.member_info.mid,
                uname:this.member_info.uname,
                num:this.member_score_set.num,
                score:this.member_info.score,
                remark:this.member_score_set.remark,
                phone:this.member_score_set.phone,
                type:'1',  //后台调整
                handle_person:'管理员',
            }

            this.$post('/pc/member/setMemberScore',params,function (res) {
                    let result =res.data
                   if(result.code==200){
                       _this.$message.success(result.msg)
                       _this.member_score_set.num=0
                       _this.member_score_set.remark=''
                      _this.show_member_info=false
                       return
                   }
                _this.$message.error(result.msg)
            })



        },
        handleChange(val){
            console.log(val);
        },
    }




  }
</script>
<style>

</style>
